import React from "react"

import Layout from "../components/layout.en"
import SEO from "../components/seo"

const NotFoundPage = (props) => (
    <Layout  location={props.location}>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Sorry, there is no such page :(</p>
  </Layout>
)

export default NotFoundPage
